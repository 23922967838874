import { QueryClientProvider } from "@tanstack/react-query";
// import "antd/dist/antd.min.css";
import "./App.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { queryClient } from "./clientProvider";
import { App as ContextApp } from "antd";

import { Suspense } from "react";
import MainRouter from "./routes";
import { AuthProvider } from "./services/authProvider";
import { Route, Routes } from "react-router-dom";
import SignIn from "./pages/SignIn";
import { ProtectedRoute } from "./services/protectedRoute";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ContextApp>
        <AuthProvider>
          <MainRouter />
          {/* <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/login" element={<SignIn />} />
            <Route
              path="/painel"
              element={
                <ProtectedRoute>
                  <MainRouter />
                </ProtectedRoute>
              }
            />
          </Routes> */}
        </AuthProvider>
      </ContextApp>
    </QueryClientProvider>
  );
}

export default App;
