import { useNavigate, Navigate } from "react-router-dom";
import { useLocalStorage } from "./localStorage";
import { createContext, useContext, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { useLoginRequest } from "../Hooks/auth/useAuth.query";
import { App } from "antd";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { notification } = App.useApp();
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(useLoginRequest, {
    onSuccess: (data) => {
      const { status, message } = data?.data;
      const { token, personal } = data?.data?.data;

      if (token.access_given !== 80) {
        notification.error({
          message: `ERROR`,
          description: "Voce Não possui acesso de escritório"
        });
        return;
      }

      if (status === 400 || status === 401 || status === 403) {
        notification.error({
          message: `ERROR ${status}`,
          description: message
        });
        return;
      }
      if (status === 200) {
        localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, token.token);
        notification.success({
          message: "Sucesso.",
          description: `${message} redirecionando ao painel. `
        });
        setUser(personal);
        navigate("/");
      }
    },
    onError: (error) => {
      notification.error({
        message: "Erro",
        description: error
      });
    }
  });

  const login = async (data) => {
    mutate(data);
  };

  const logout = () => {
    window.localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
    window.localStorage.removeItem("@tokenMobile");
    window.localStorage.removeItem("user");
    // setUser(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      isLoading
    }),
    [user, isLoading, logout]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
