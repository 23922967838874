import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  notification
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";

const { Search } = Input;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

function Create() {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);

  const { data: dataSelectPeopleGN, status: statusPeopleGN } = useQuery(
    ["people", 1],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_API}/v1/profile/people-by-role?role=50&expand=profile`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_ACCESS_TOKEN
              )}`
            }
          }
        )
        .then((res) => {
          return res.data.data;
        })
  );

  const handleSearchPostal = async (postalcode) => {
    setIsLoading(true);
    const { data } = await axios
      .get(`https://brasilapi.com.br/api/cep/v2/${postalcode}`)
      .then((res) => {
        api.success({
          message: "OK"
        });
        console.log(res);
        return res;
      })
      .catch((err) => {
        api.error({
          message: "CEP não encontrado",
          description: "Verifique o cep digitado e tente novamente"
        });
        return err.message;
      });

    form.setFieldsValue({
      street: data?.street,
      neighborhood: data?.neighborhood,
      city: data?.city,
      state: data?.state
    });
    setIsLoading(false);
  };

  const createOffice = (user) => {
    axios
      .post(`${process.env.REACT_APP_API}/v1/offices/create`, user, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      })
      .then((res) => {
        api.open({
          message: "Sucesso",
          description: "Plano criado com sucesso."
        });
        form.resetFields();
        navigate("/escritorios");
        queryClient.invalidateQueries("adminOffices");
      })
      .catch((err) => {
        api.open({
          message: "Notification Title",
          description: err.response.data.data.getErrors.username[0]
        });
      });
  };
  const { mutate, isLoading: sendingForm } = useMutation(createOffice, {
    onSuccess: () => {
      queryClient.invalidateQueries("adminOffices");
      api.open({
        message: "Sucesso",
        description: "Escritorio criado com sucesso."
      });
      form.resetFields();
      navigate("/escritorios");
    }
  });
  const onFinish = (user) => {
    mutate(user);
  };

  return (
    <div className="layout-content">
      {contextHolder}
      <Content className="p-0">
        <Row gutter={[24, 5]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title="Cadastrar"
            >
              <Row gutter={[24, 5]} className="my-25 text-center">
                <Col xs="18" xl={18}>
                  <Form
                    {...layout}
                    form={form}
                    name="create-user"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="name"
                      label="Nome do escritorio"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label="Telefone"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="whatsapp" label="Whatsapp">
                      <Input />
                    </Form.Item>
                    {/* <Form.Item name="owner" label="Dono">
                      <Input />
                    </Form.Item> */}
                    <Form.Item
                      name="owner"
                      label="Dono do escritório"
                      rules={[{ required: true }]}
                    >
                      <Select>
                        {statusPeopleGN === "loading" && (
                          <Option key="loading" disabled>
                            Loading...
                          </Option>
                        )}
                        {statusPeopleGN === "error" && (
                          <Option key="loading" disabled>
                            erro
                          </Option>
                        )}
                        {statusPeopleGN === "success" &&
                          dataSelectPeopleGN?.map((item) => (
                            <>
                              <Option key={item?.id} value={item?.user_id}>
                                {item?.profile?.name}
                              </Option>
                            </>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="postal_code" label="CEP">
                      <Search
                        loading={isLoading}
                        placeholder="Coloque o CEP"
                        onSearch={handleSearchPostal}
                        enterButton
                      />
                      {/* <Input /> */}
                    </Form.Item>
                    <Form.Item name="street" label="Endereço">
                      <Input />
                    </Form.Item>
                    <Form.Item name="neighborhood" label="Bairro">
                      <Input />
                    </Form.Item>
                    <Form.Item name="city" label="Cidade">
                      <Input />
                    </Form.Item>
                    <Form.Item name="state" label="UF">
                      <Input />
                    </Form.Item>
                    <Form.Item name="country" label="País">
                      <Input />
                    </Form.Item>
                    <Form.Item name="complement" label="Complemento">
                      <Input />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                      <Button
                        loading={!!sendingForm}
                        style={{ width: "100%", marginRight: 0 }}
                        type="primary"
                        htmlType="submit"
                      >
                        CRIAR
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </div>
  );
}

export default Create;
