import { Descriptions, Divider, Flex } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useProfileFetchRequest } from "../../Hooks/manager/useManager.query";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LinkOutlined
} from "@ant-design/icons";

const ManagerMaster = () => {
  const { id } = useParams();
  const [hiddenDocument, setHiddenDocument] = useState(false);
  const { data: dataProfile } = useProfileFetchRequest(id);
  const profile = dataProfile?.data.data.manager;

  const handleHiddenDocument = () => {
    setHiddenDocument(!hiddenDocument);
  };

  return (
    <>
      <Flex justify="space-between" align="center">
        <Title level={4}>Gerente</Title>
        <Link to={`/gerente/${profile.id}/informacoes`}>
          <LinkOutlined />
        </Link>
      </Flex>
      <Divider />
      <Descriptions>
        {/* <Descriptions.Item label="Documento" span={3}>
          {hiddenDocument ? (
            <>
              <EyeInvisibleOutlined
                onClick={handleHiddenDocument}
                className="mr-5"
              />{" "}
              {profile?.document}{" "}
            </>
          ) : (
            <>
              {" "}
              <EyeOutlined
                onClick={handleHiddenDocument}
                className="mr-5"
              />{" "}
              ******{" "}
            </>
          )}
        </Descriptions.Item> */}
        <Descriptions.Item label="Nome">{profile?.name}</Descriptions.Item>
        <Descriptions.Item label="Email">{profile?.email}</Descriptions.Item>
        <Descriptions.Item label="Telefone">{profile?.phone}</Descriptions.Item>
        <Descriptions.Item label="Whatsapp">
          {profile?.whatsapp}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ManagerMaster;
