import { useQuery } from "@tanstack/react-query";
import {
  Navigate,
  useNavigate,
  useRoutes,
  useLocation
} from "react-router-dom";
import Main from "../components/layout/Main";
import Batch from "../pages/Batch";
import Copy from "../pages/Copy";
import Home from "../pages/Home";
import Plans from "../pages/Planos";
import PlansCreate from "../pages/Planos/create";
import Reports from "../pages/Reports";
import Investor from "../pages/Reports/list";
import Manager from "../pages/Reports/manager";
import RetroEntry from "../pages/RetroEntry";
import Role from "../pages/Role";
import SignIn from "../pages/SignIn";
import User from "../pages/User";
import Transactions from "../pages/User/transactions";
import Users from "../pages/Users";
import CreateUser from "../pages/Users/create";
import EditUser from "../pages/Users/edit";
import Withdrawal from "../pages/Withdrawal";
import api from "../services/api";
import { isAuthenticated } from "../services/auth";
import List from "../pages/Reports/list";
import Offices from "../pages/Offices";
import Create from "../pages/Offices/create";
import Notifications from "../pages/Notifications";
import Entry from "../pages/Reports/entry";
import PersonalInformations from "../pages/User/personalInformations";
import ManagerAccount from "../pages/User/managerAccount";
import Gains from "../pages/User/gains";
import Documentation from "../pages/User/documentation";
import PersonalNotifications from "../pages/User/notifications";
import PersonalTransactions from "../pages/User/transactions";
import CreateNotification from "../pages/Notifications/create";
import NotificationCreate from "../pages/User/createNotification";
import Log from "../pages/Log";
import { useAccessFetchRequest } from "../Hooks/access/useAccess.query";
import Promotions from "../pages/Promotions";
import CreatePromotion from "../pages/Promotions/create";
import { BackgroundFetch, PageLoading } from "../components/Loading";
import ManagerGain from "../pages/Manager";
import CreateManager from "../pages/Manager/create";
import ManagerProfile from "../pages/Manager/profile";
import Informations from "../pages/Manager/informations";
import ManagerMaster from "../pages/Manager/manager";
import WalletManager from "../pages/Manager/portfolio";
import PortfolioManager from "../pages/Manager/portfolio";
import InvestorProfileManager from "../pages/Manager/investor";

export default function MainRouter() {
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const page = pathname.split("/");
  pathname = pathname.replace("/", "");

  const { data, isLoading: loading } = useAccessFetchRequest();
  const access = data?.data?.access;

  if (pathname === "login" && data?.status === 200) {
    navigate("/painel");
  }

  return useRoutes([
    {
      path: "/",
      element: <Main />,
      children: [
        { element: <Navigate to="/" /> },
        {
          path: "",
          element: <Home />
        },
        {
          path: "gerentes",
          children: [
            { path: "", element: <ManagerGain /> },
            { path: "criar", element: <CreateManager /> }
          ]
        },
        {
          path: "gerente",
          children: [
            {
              path: ":id",
              element: <ManagerProfile />,
              children: [
                {
                  path: "informacoes",
                  children: [
                    {
                      path: "",
                      element: <Informations />
                    }
                  ]
                },
                {
                  path: "gerente",
                  children: [
                    {
                      path: "",
                      element: <ManagerMaster />
                    }
                  ]
                },
                {
                  path: "carteira",
                  children: [
                    {
                      path: "",
                      element: <PortfolioManager />
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: "investidores",
          children: [{ path: "", element: <Users /> }]
        },
        {
          path: "investidor",
          children: [
            {
              path: ":id",
              element: <User />,
              children: [
                {
                  path: "informacoes",
                  children: [
                    {
                      path: "",
                      element: <PersonalInformations />
                    }
                  ]
                },
                {
                  path: "gerente",
                  children: [
                    {
                      path: "",
                      element: <ManagerAccount />
                    }
                  ]
                },
                {
                  path: "conta",
                  children: [
                    {
                      path: "",
                      element: <Gains />
                    }
                  ]
                },
                {
                  path: "documentos",
                  children: [
                    {
                      path: "",
                      element: <Documentation />
                    }
                  ]
                },
                {
                  path: "transacoes",
                  children: [
                    {
                      path: "",
                      element: <Transactions />
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: "login",
      element: <SignIn />
    }
  ]);
}
