import { Card, Col, Descriptions, Divider, Flex } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useProfileFetchRequest } from "../../Hooks/manager/useManager.query";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const Informations = () => {
  const { id } = useParams();
  const [hiddenDocument, setHiddenDocument] = useState(false);
  const { data: dataProfile } = useProfileFetchRequest(id);
  const profile = dataProfile?.data.data;

  const handleHiddenDocument = () => {
    setHiddenDocument(!hiddenDocument);
  };

  return (
    <>
      <Flex justify="space-between" align="center">
        <Title level={4}>Informaçoes Pessoal</Title>
      </Flex>
      <Divider />
      <Col md={24}>
        <Card bordered={true}></Card>
      </Col>
      <Divider />
      <Descriptions>
        <Descriptions.Item label="Documento" span={3}>
          {hiddenDocument ? (
            <>
              <EyeInvisibleOutlined
                onClick={handleHiddenDocument}
                className="mr-5"
              />{" "}
              {profile?.document}{" "}
            </>
          ) : (
            <>
              {" "}
              <EyeOutlined
                onClick={handleHiddenDocument}
                className="mr-5"
              />{" "}
              ******{" "}
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Nome">{profile?.name}</Descriptions.Item>
        <Descriptions.Item label="Email">{profile?.email}</Descriptions.Item>
        <Descriptions.Item label="Telefone">{profile?.phone}</Descriptions.Item>
        <Descriptions.Item label="Whatsapp">
          {profile?.whatsapp}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default Informations;
