import {
  EyeInvisibleOutlined,
  EyeOutlined,
  FilePdfOutlined,
  MailOutlined,
  ProfileOutlined,
  UserOutlined
} from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Avatar,
  Button,
  Card,
  Col,
  Descriptions,
  Flex,
  Form,
  Input,
  List,
  Menu,
  Modal,
  Popconfirm,
  Progress,
  Radio,
  Row,
  Statistic,
  notification
} from "antd";
import axios from "axios";
import "dayjs/locale/pt-br";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
// import Identicon from "react-identicons";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteUser,
  useInvestorFetchRequest
} from "../../Hooks/investor/useInvestor.query";
import { useManagerFetchRequest } from "../../Hooks/manager/useManager.query";
import BgProfile from "../../assets/images/bg-profile.jpg";
import convesionImg from "../../assets/images/face-2.jpg";
import MyModal from "../../components/MyModal";
import DocumentAssigned from "../../components/uploads/DocumentAssigned";
import { dateEndContract, daysToEndContract } from "../../helpers/dateHelper";
import { formatCurrency } from "../../helpers/moneyFormat";
import { plan } from "../../helpers/plans";
import Title from "antd/es/typography/Title";
import Meta from "antd/es/card/Meta";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  };
}

const User = () => {
  const navigate = useNavigate();
  const { id, params } = useParams();
  const location = useLocation();

  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idProfile, setIdProfile] = useState(null);
  const [aport, setAport] = useState({ title: "", show: false });
  const [entry, setEntry] = useState({ title: "", show: false });
  const [withdraw, setWithdraw] = useState({ title: "", show: false });
  const [withdrawBeyond, setWithdrawBeyond] = useState({
    title: "",
    show: false
  });
  const [withdrawTotal, setWithdrawTotal] = useState({
    title: "",
    show: false
  });
  const [reinvestment, setReinvestment] = useState({ title: "", show: false });
  const queryClient = useQueryClient();
  const [hiddenDocument, setHiddenDocument] = useState(false);

  const { data: dataInvestor, isLoading } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const { data: dataManager } = useManagerFetchRequest(investor?.manager?.id);
  const manager = dataManager?.data;

  const put = async (dataForm) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/v1/transaction/create`,
      dataForm,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );

    return response.data;
  };
  const putTotal = async (dataForm) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/v1/withdrawal/withdrawal-total`,
      dataForm,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );

    return response.data;
  };
  const putBeyond = async (dataForm) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/v1/withdrawal/withdrawal-beyond-profitability`,
      dataForm,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );

    return response.data;
  };
  const { mutate } = useMutation(put, {
    onSuccess: (data) => {
      setIsModalVisible(false);
      form.resetFields();
      queryClient.invalidateQueries("adminInvestor");
      api.success({
        message: "Sucesso",
        description: "Lançamento feito com sucesso."
      });
    },
    onError: (error) => {
      api.error({
        message: `Error: [${error.response.data.status}] ${error.response.data.name}`,
        description: error.response.data.message
      });
    }
  });
  const { mutate: mutateTotal } = useMutation(putTotal, {
    onSuccess: (data) => {
      setIsModalVisible(false);
      form.resetFields();
      queryClient.invalidateQueries("adminInvestor");
      api.success({
        message: "Sucesso",
        description: "Lançamento feito com sucesso."
      });
    },
    onError: (error) => {
      api.error({
        message: `Error: [${error.response.data.status}] ${error.response.data.name}`,
        description: error.response.data.message
      });
    }
  });
  const { mutate: mutateBeyond } = useMutation(putBeyond, {
    onSuccess: (data) => {
      setIsModalVisible(false);
      form.resetFields();
      queryClient.invalidateQueries("adminInvestor");
      api.success({
        message: "Sucesso",
        description: "Lançamento feito com sucesso."
      });
    },
    onError: (error) => {
      api.error({
        message: `Error: [${error.response.data.status}] ${error.response.data.name}`,
        description: error.response.data.message
      });
    }
  });
  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    useDeleteUser,
    {
      onSuccess: (data) => {
        form.resetFields();
        queryClient.invalidateQueries("adminInvestor");
        if (data.data.status === 400) {
          api.error({
            message: "Error",
            description: data.data.message
          });
          return;
        }
        api.success({
          message: "Sucesso",
          description: data.data.message
        });
        delay(2000).then(() => {
          setOpenModalDelete(false);
          navigate(`/investidor`);
        });
      },
      onError: (error) => {
        api.error({
          message: `Error: [${error.data.status}] ${error.data.name}`,
          description: error.data.message
        });
      }
    }
  );

  const onFinish = (values) => {
    mutate(values);
  };
  const onFinishWithdrawTotal = (values) => {
    mutateTotal(values);
  };
  const onFinishWithdrawBeyond = (values) => {
    mutateBeyond(values);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAport({ title: "", show: false });
    setEntry({ title: "", show: false });
    setWithdraw({ title: "", show: false });
    setReinvestment({ title: "", show: false });
  };

  const handleClickAport = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setAport({ title: "Aportar Valor", show: true });
  };

  const handleClickEntry = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setEntry({ title: "% de ganho mês", show: true });
  };

  const handleClickWithdraw = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setWithdraw({ title: "Sacar valores", show: true });
  };
  const handleClickWithdrawTotal = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setWithdrawTotal({ title: "Sacar valor total", show: true });
  };
  const handleClickWithdrawBeyond = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setWithdrawBeyond({ title: "Sacar valor parcial", show: true });
  };

  const handleClickReinvestment = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setReinvestment({ title: "Reinvestimento", show: true });
  };

  const handleClickEdit = (e) => {
    setIdProfile(e);
    navigate(`/investidor/editar/${e}`);
  };

  const handleTransactions = (e) => {
    setIdProfile(e);
    navigate(`/investidor/historico/${e}`);
  };

  const handleHiddenDocument = () => {
    setHiddenDocument(!hiddenDocument);
  };

  const confirmDelete = () => {
    mutateDelete(investor?.user_id);
  };
  //função para chamar script de deletar

  const handleShowModalConfirmDelete = () => {
    setOpenModalDelete(true);
  };
  if (isLoading) {
    return "caregando...";
  }

  const onClick = (e) => {
    console.log("click", e);
    navigate(e.key);
  };
  return (
    <>
      {contextHolder}
      <Row gutter={8}>
        <Col md={8} sm={24}>
          <Row>
            <Card
              style={{
                width: "100%"
              }}
              cover={
                <img
                  alt="example"
                  src={`https://api.dicebear.com/8.x/shapes/svg?seed=Felix${investor?.name}`}
                  height={200}
                  style={{ width: "100%", objectFit: "cover" }}
                  // src="https://i.pinimg.com/564x/f1/98/9f/f1989f706500904c2f664b6fed8994b1.jpg"
                />
              }
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                justify="center"
                style={{
                  marginTop: -100
                }}
              >
                {/* <Progress
                  type="circle"
                  percent={20}
                  size={140}
                  style={{ position: "absolute", marginTop: -10 }}
                /> */}
                <Avatar
                  size={120}
                  src={
                    <img
                      src={
                        investor?.imageUrl ||
                        `https://api.dicebear.com/8.x/initials/svg?seed=${investor?.name}&backgroundType=gradientLinear,solid&backgroundColor=b6e3f4,c0aede,d1d4f9&fontWeight=600`
                      }
                      alt={investor?.name}
                    />
                  }
                  style={{ backgroundColor: "#87d068" }}
                  icon={<UserOutlined />}
                />
              </Flex>
              <Meta
                title={investor?.name}
                description={investor?.myPlan?.plan}
                style={{ marginTop: 15 }}
              />

              <Menu
                selectedKeys={[location.pathname.split("/").at(-1)]}
                onClick={onClick}
                style={{ marginTop: 15 }}
                items={[
                  getItem(
                    "Informaçoes Pessoais",
                    "informacoes",
                    <ProfileOutlined />
                  ),
                  getItem("Gerente da conta", "gerente", <ProfileOutlined />),
                  getItem("Wallet", "conta", <ProfileOutlined />),
                  getItem("Documentos", "documentos", <ProfileOutlined />),
                  getItem("Transações", "transacoes", <ProfileOutlined />)
                ]}
              />
            </Card>
          </Row>
        </Col>
        <Col md={16} sm={24}>
          <Card
            style={{
              width: "100%"
            }}
          >
            <Outlet />
          </Card>
        </Col>

        <Modal
          title="Confirmação de Exclusão de Conta"
          open={openModalDelete}
          onOk={confirmDelete}
          onCancel={() => setOpenModalDelete(false)}
          okText="Sim proseguir com a exclusão"
          cancelText="Cancelar"
          okButtonProps={{
            type: "dashed",
            danger: true,
            loading: loadingDelete
          }}
        >
          <p>
            Você está prestes a excluir permanentemente a conta de{" "}
            {investor.name}. Por favor, leve em consideração que esta ação é
            irreversível e todos os dados, histórico e informações associadas
            serão perdidos.
          </p>
          <p>
            Tem certeza de que deseja prosseguir com a exclusão da sua conta?
          </p>
        </Modal>
      </Row>
    </>
  );
};

export default User;
