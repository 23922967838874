import {
  Typography,
  Avatar,
  Card,
  Col,
  List,
  Popover,
  Row,
  Spin,
  Statistic
} from "antd";
import React from "react";
import {
  useWalletAmountBalanceFetchRequest,
  useWalletExpenseBalanceFetchRequest
} from "../../Hooks/wallet/useWallet.query";
import { useInvestorsFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { formatCurrency } from "../../helpers/moneyFormat";
import Title from "antd/es/typography/Title";
import { usePromotionsRequest } from "../../Hooks/promotions/usePromotions.query";
import {
  useManagersRequest,
  useRankingManagersRequest
} from "../../Hooks/manager/useManager.query";
import DailyMotivationalQuote from "../../components/Messages/dailyMotivationalQuote";
import { Link } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";

const Home = () => {
  const { data: dataInvestors, isLoading } =
    useInvestorsFetchRequest("profile, wallet");
  const investor = dataInvestors?.data?.data;
  const { data: dataAmount, isLoading: loadingAmount } =
    useWalletAmountBalanceFetchRequest("amount");
  const { data: dataExpense, isLoading: loadingExpense } =
    useWalletExpenseBalanceFetchRequest("expense");
  const { data: dataManagers, isLoading: loadingManagers } =
    useManagersRequest();
  const { data: dataPromotions } = usePromotionsRequest();
  // const { data: dataRanking } = useRankingManagersRequest();

  const managers = dataManagers?.data?.data;
  const promotions = dataPromotions?.data.data;
  // const ranking = dataRanking?.data.data;

  // function userIsBlock(usuarios) {
  //   let contagem = 0;

  //   for (const usuario of usuarios) {
  //     if (usuario?.user?.status !== 10) {
  //       contagem++;
  //     }
  //   }

  //   return contagem;
  // }
  // function userNotBlock(usuarios) {
  //   let contagem = 0;

  //   for (const usuario of usuarios) {
  //     if (usuario?.user?.status === 10) {
  //       contagem++;
  //     }
  //   }

  //   return contagem;
  // }

  // function isManager(usuarios) {
  //   let contagem = 0;

  //   for (const usuario of usuarios) {
  //     if (usuario?.user?.access_given === 50) {
  //       contagem++;
  //     }
  //   }

  //   return contagem;
  // }

  if (isLoading) {
    return (
      <div
        className="layout-content"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div className="layout-content">
      <Row gutter={5}>
        <Col span={24} className="mb-24">
          <Row gutter={5}>
            <Col
              xs={{ flex: "100%" }}
              sm={{ flex: "50%" }}
              md={{ flex: "50%" }}
              lg={{ flex: "25%" }}
              xl={{ flex: "25%" }}
              className="mb-24"
            >
              <Card
                loading={loadingAmount}
                bordered={false}
                size="small"
                className="criclebox h-full"
              >
                <Statistic
                  title="Total de saldo em operação"
                  value={formatCurrency(dataAmount?.data)}
                  valueStyle={{
                    color: "#3f8600"
                  }}
                />
              </Card>
            </Col>
            <Col
              xs={{ flex: "100%" }}
              sm={{ flex: "50%" }}
              md={{ flex: "50%" }}
              lg={{ flex: "25%" }}
              xl={{ flex: "25%" }}
              className="mb-24"
            >
              <Card bordered={false} size="small" className="criclebox h-full">
                <Statistic
                  title="Valores ja repassados para investidores"
                  value={formatCurrency(dataExpense?.data)}
                  valueStyle={{
                    color: "#cf1322"
                  }}
                />
              </Card>
            </Col>
            {/* <Col
              xs={{ flex: "100%" }}
              sm={{ flex: "50%" }}
              md={{ flex: "50%" }}
              lg={{ flex: "25%" }}
              xl={{ flex: "25%" }}
              className="mb-24"
            >
              <Card bordered={false} size="small" className="criclebox h-full">
                <Statistic
                  title="Total de usuários - Ativos / Não Ativos"
                  value={`${userNotBlock(investor)} / ${userIsBlock(investor)}`}
                />
              </Card>
            </Col> */}
            {/* <Col
              xs={{ flex: "100%" }}
              sm={{ flex: "50%" }}
              md={{ flex: "50%" }}
              lg={{ flex: "25%" }}
              xl={{ flex: "25%" }}
              className="mb-24"
            >
              <Card
                bordered={false}
                size="small"
                className="criclebox h-full w-full"
              >
                <Statistic
                  title="Quantidade de G.N"
                  value={isManager(investor)}
                />
              </Card>
            </Col> */}
          </Row>
        </Col>
      </Row>

      <Row gutter={5}>
        <Col
          xs={{ flex: 24, order: 2 }}
          sm={{ flex: 16, order: 2 }}
          md={{ flex: 14, order: 2 }}
          lg={{ flex: 16, order: 1 }}
          xl={{ flex: 15, order: 1 }}
        >
          <Row gutter={5}>
            <Col span={24} className="mb-24">
              <Card title="Promoções ativas">
                <List
                  pagination={{
                    onChange: (page) => {
                      console.log(page);
                    },
                    pageSize: 5
                  }}
                  itemLayout="horizontal"
                  dataSource={promotions}
                  renderItem={(item, index) => (
                    <List.Item
                      extra={
                        <p>
                          {item.start_date} <small>até</small> {item.end_date}
                        </p>
                      }
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={`https://api.dicebear.com/8.x/identicon/svg?seed=${index}`}
                          />
                        }
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>

            <Col span={24} className="mb-24">
              <Card title="Gerentes de Negocios ativos">
                <List
                  loading={loadingManagers}
                  pagination={{
                    onChange: (page) => {
                      console.log(page);
                    },
                    pageSize: 5
                  }}
                  itemLayout="horizontal"
                  dataSource={managers}
                  renderItem={(item, index) => (
                    <List.Item
                      extra={
                        <Typography.Text>
                          Valor de carteira:{" "}
                          {formatCurrency(item?.walletAmount, "USD")}{" "}
                        </Typography.Text>
                      }
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={`https://api.dicebear.com/8.x/fun-emoji/svg?seed=${item?.profile.name}`}
                          />
                        }
                        title={
                          <Link to={`/gerente/${item?.profile.id}/informacoes`}>
                            {item?.profile.name}
                          </Link>
                        }
                        description={item?.office}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        {/* // */}
        <Col
          xs={{ flex: 24, order: 1 }}
          sm={{ flex: 8, order: 1 }}
          md={{ flex: 10, order: 1 }}
          lg={{ flex: 8, order: 2 }}
          xl={{ flex: 9, order: 2 }}
        >
          <Row gutter={10}>
            <Col xs={24} className="mb-24">
              <div
                style={{
                  backgroundSize: "cover",
                  backgroundImage:
                    "url(https://demos.creative-tim.com/muse-ant-design-dashboard-pro/static/media/info-card-2.9a5119d1.jpg)",
                  height: 200,
                  width: "100%",
                  borderRadius: "10px"
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    padding: "10px"
                  }}
                >
                  <Title style={{ color: "#efefef" }} level={4}>
                    Bem Vindo ✌️
                  </Title>
                  <DailyMotivationalQuote />
                </div>
              </div>
            </Col>

            {/* <Col xs={24} className="mb-24">
              <Card title="Ranking de G.N.">
                <List
                  itemLayout="horizontal"
                  dataSource={ranking}
                  renderItem={(item, index) => (
                    <List.Item extra={<p>{formatCurrency(item.amount)}</p>}>
                      <Title
                        level={5}
                        style={{
                          width: 30,
                          height: 45,
                          textAlign: "center",
                          color: "#222222"
                        }}
                      >
                        {index + 1} º
                      </Title>
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={`https://api.dicebear.com/8.x/fun-emoji/svg?seed=${item.user_name}`}
                          />
                        }
                        title={item.user_name}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
