import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Statistic,
  notification
} from "antd";
import React, { useState } from "react";
import { formatCurrency } from "../../helpers/moneyFormat";
import { useParams } from "react-router-dom";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import Title from "antd/es/typography/Title";
import ReactApexChart from "react-apexcharts";
import MyModal from "../../components/MyModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useTransactionPostRequest,
  useTransactionTotalWithdraw,
  useWithdrawBeyondPostRequest
} from "../../Hooks/transactions/useTransactions.query";

const Gains = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idProfile, setIdProfile] = useState(null);
  const [aport, setAport] = useState({ title: "", show: false });
  const [entry, setEntry] = useState({ title: "", show: false });
  const [withdraw, setWithdraw] = useState({ title: "", show: false });
  const [withdrawBeyond, setWithdrawBeyond] = useState({
    title: "",
    show: false
  });
  const [withdrawTotal, setWithdrawTotal] = useState({
    title: "",
    show: false
  });
  const [reinvestment, setReinvestment] = useState({ title: "", show: false });

  const { data: dataInvestor, isLoading } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const arrayEntrada22 = investor?.transactions?.map((transaction) => ({
    amount: transaction?.amount_money,
    month: transaction?.month_year,
    type: transaction?.type_transaction,
    percent: transaction?.percent
  }));
  const dadosAgrupados222 = arrayEntrada22?.reduce(
    (agrupados, dado) => {
      const { amount, month, type, percent } = dado;
      const valor = parseFloat(amount);

      if (month && !isNaN(valor)) {
        if (type === 1) {
          if (agrupados.percent[month]) {
            agrupados.percent[month] += percent;
          } else {
            agrupados.percent[month] = percent;
          }
        } else {
          if (agrupados.ganhos[month]) {
            agrupados.ganhos[month] += valor;
          } else {
            agrupados.ganhos[month] = valor;
          }
        }
      }

      return agrupados;
    },
    { percent: {}, ganhos: {} }
  );
  const arrayResultadoGanhos = Object.keys(dadosAgrupados222?.ganhos || {}).map(
    (data) => ({
      amount: dadosAgrupados222.ganhos[data],
      mes: data
    })
  );
  const arrayResultadoPercent = Object.keys(
    dadosAgrupados222?.percent || {}
  ).map((data) => ({
    amount: dadosAgrupados222.percent[data],
    mes: data
  }));

  const narrXPercent = arrayResultadoPercent?.map((a) => a.mes) || [];
  const narrDataGanhos = arrayResultadoGanhos?.map((a) => a.amount) || [];
  const narrDataPercent = arrayResultadoPercent?.map((a) => a.amount) || [];
  const narrXGanhos = arrayResultadoGanhos?.map((a) => a.mes) || [];
  const chart = {
    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 2
          // dataLabels: {
          //   position: "top"
          // }
        }
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return formatCurrency(val);
        },
        offsetY: 0,
        style: {
          fontSize: "12px",
          colors: ["#eee"]
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#1890aa"]
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#900"]
        }
      },
      xaxis: {
        categories: narrXGanhos
      },
      yaxis: {
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#000"]
          },
          formatter: function (val) {
            return formatCurrency(val);
          }
        }
      },
      title: {
        text: "Ganho Mensal",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444"
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return formatCurrency(val);
          }
        }
      }
    },

    series: [
      {
        name: "Valor fechamento Mensal",
        data: narrDataGanhos,
        color: "#1890ff"
      }
    ]
  };
  const chart2 = {
    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",
        toolbar: {
          show: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `${val} %`;
        },
        offsetY: -20,
        style: {
          fontSize: "10px",
          colors: ["#555"],
          transform: "rotate(90)"
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#1890aa"]
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#900"]
        }
      },
      xaxis: {
        categories: narrXPercent,
        position: "top",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#000"]
          },
          formatter: function (val) {
            return `${val} %`;
          }
        }
      },
      title: {
        text: "Porcentagem mes",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444"
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${val} %`;
          }
        }
      }
    },

    series: [
      {
        name: "Porcentagem mensal",
        data: narrDataPercent,
        color: "#1890ff"
      }
    ]
  };

  return (
    <>
      {contextHolder}
      <Title level={4}>Valor em Carteira</Title>
      <Divider />
      <Row gutter={10}>
        <Col md={8} sm={24}>
          <Card bordered={true}>
            <Statistic
              title="Valor Aportado"
              value={formatCurrency(
                
               
              
                investor?.wallet?.income,
                investor?.currency
              )}
              valueStyle={{
                color: "#3f8600"
              }}
            />
          </Card>
        </Col>
        <Col md={8} sm={24}>
          <Card bordered={true}>
                
               
              
            <Statistic
              title="Valor em operação"
              value={formatCurrency(
                investor?.wallet?.amount,
                investor?.currency
              )}
              valueStyle={{
                color: "#3f8600"
              }}
            />
          </Card>
                
               
              
        </Col>
        <Col md={8} sm={24}>
          <Card bordered={true}>
            <Statistic
              title="Valor ja sacado"
              value={formatCurrency(
                investor?.wallet?.expense,
                investor?.currency
              )}
              valueStyle={{
                color: "#cf1322"
              }}
            />
          </Card>
        </Col>
      </Row>

      <br />
      <Row gutter={[10]}>
        {/* <Col span={24} md={12} className="mb-24 ">
          <Card bordered={false} className="criclebox h-full">
            <ReactApexChart
              className=""
              options={chart.options}
              series={chart.series}
              type="bar"
              height={220}
            />
          </Card>
        </Col> */}
        {/* <Col span={24} md={12} className="mb-24 ">
          <Card bordered={false} className="criclebox h-full">
            <ReactApexChart
              className=""
              options={chart2.options}
              series={chart2.series}
              type="bar"
              height={220}
            />
          </Card>
        </Col> */}
      </Row>
    </>
  );
};

export default Gains;
