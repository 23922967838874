import { Avatar, Card, Col, Flex, Menu, Row } from "antd";
import React, { useEffect } from "react";
import {
  Outlet,
  useLocation,
  useMatches,
  useNavigate,
  useParams
} from "react-router-dom";
import { useProfileFetchRequest } from "../../Hooks/manager/useManager.query";
import { ProfileOutlined, UserOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  };
}

const ManagerProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const location = useLocation();

  const { data: dataProfile } = useProfileFetchRequest(id);
  const profile = dataProfile?.data.data;

  const onClick = (e) => {
    navigate(e.key);
  };

  return (
    <>
      <Row gutter={8}>
        <Col md={8} sm={24}>
          <Row>
            <Card
              style={{
                width: "100%"
              }}
              cover={
                <img
                  alt="example"
                  src={`https://api.dicebear.com/8.x/shapes/svg?seed=Felix${profile?.name}`}
                  height={150}
                  style={{ width: "100%", objectFit: "cover" }}
                  // src="https://i.pinimg.com/564x/f1/98/9f/f1989f706500904c2f664b6fed8994b1.jpg"
                />
              }
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                justify="center"
                style={{
                  marginTop: -100
                }}
              >
                <Avatar
                  size={120}
                  src={
                    <img
                      src={
                        profile?.imageUrl ||
                        `https://api.dicebear.com/8.x/initials/svg?seed=${profile?.name}&backgroundType=gradientLinear,solid&backgroundColor=b6e3f4,c0aede,d1d4f9&fontWeight=600`
                      }
                      alt={profile?.name}
                    />
                  }
                  style={{ backgroundColor: "#87d068" }}
                  icon={<UserOutlined />}
                />
              </Flex>
              <Meta
                title={profile?.name}
                // description={profile?.myPlan?.plan}
                style={{ marginTop: 15 }}
              />
              <Menu
                onClick={onClick}
                style={{ marginTop: 15 }}
                selectedKeys={[location.pathname.split("/").at(-1)]}
                items={[
                  getItem(
                    "Informaçoes Pessoais",
                    "informacoes",
                    <ProfileOutlined />
                  ),
                  getItem("Carteira", "carteira", <ProfileOutlined />),
                  getItem("Gerente Sênior", "gerente", <ProfileOutlined />)
                  // getItem("Documentos", "documentos", <ProfileOutlined />)
                ]}
              />
            </Card>
          </Row>
        </Col>
        <Col md={16} sm={24}>
          <Card
            style={{
              width: "100%"
            }}
          >
            <Outlet />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ManagerProfile;
