import React from "react";
import { App, Button, Card, Col, Flex, Form, Input, Row, Select } from "antd";
import Title from "antd/es/typography/Title";
import { useNavigate } from "react-router-dom";
import { IMaskInput } from "react-imask";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

const CreateManager = () => {
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { data: dataSelectPeopleGN, status: statusPeopleGN } = useQuery(
    ["people", 1],
    () =>
      axios
        .get(`${process.env.REACT_APP_API}/v1/profile/office-managers`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_ACCESS_TOKEN
            )}`
          }
        })
        .then((res) => {
          return res.data.data;
        })
  );

  const createUser = async (user) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/v1/profile/create-manager-in-office`,
        user,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_ACCESS_TOKEN
            )}`
          }
        }
      )
      .then((res) => {
        form.resetFields();
        api.open({
          message: "Sucesso",
          description: res.message
        });
        navigate("/investidor");
      })
      .catch((err) => {
        api.open({
          message: "erro",
          description: `erro ao criar ${err.message}`
        });
      });
  };
  const { mutate, isLoading } = useMutation(createUser, {
    onSuccess: (data) => {
      api.open({
        message: "Sucesso 2",
        description: data.message
      });
      queryClient.invalidateQueries("people");
    }
  });

  const onFinish = (sender) => {
    mutate(sender);
  };
  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Title level={4}>Novo Gerente</Title>
      </Flex>

      <Card bordered={false} className="criclebox tablespace mb-24">
        <Row gutter={[24, 5]} className="my-25 text-center">
          <Col xs="20" xl={20}>
            <Form
              {...layout}
              form={form}
              name="create-user"
              onFinish={onFinish}
            >
              <Form.Item
                name="name"
                label="Nome Completo"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="whatsapp" label="Whatsapp">
                <IMaskInput
                  mask="(00) 00000-0000"
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                    width: "100%",
                    border: "1px solid #cccccc",
                    color: "#777777",
                    paddingLeft: "10px",
                    fontWeight: 600
                  }}
                />
              </Form.Item>

              <Form.Item
                name="username"
                label="CPF"
                rules={[{ required: true }]}
              >
                <IMaskInput
                  mask="000.000.000-00"
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                    width: "100%",
                    border: "1px solid #cccccc",
                    color: "#777777",
                    paddingLeft: "10px",
                    fontWeight: 600
                  }}
                />
              </Form.Item>

              <Form.Item
                name="email"
                label="E-mail"
                rules={[{ required: true, type: "email" }]}
              >
                <Input type="mail" />
              </Form.Item>

              <Form.Item
                name="manager"
                label="G.N"
                rules={[{ required: true }]}
              >
                <Select>
                  {statusPeopleGN === "loading" && (
                    <Option key="loading" disabled>
                      Loading...
                    </Option>
                  )}
                  {statusPeopleGN === "error" && (
                    <Option key="loading" disabled>
                      erro
                    </Option>
                  )}
                  {statusPeopleGN === "success" &&
                    dataSelectPeopleGN?.map((item) => (
                      <>
                        <Option key={item?.id} value={item?.user_id}>
                          {item?.profile?.name}
                        </Option>
                      </>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  loading={!!isLoading}
                  style={{ width: "100%", marginRight: 0 }}
                  type="primary"
                  htmlType="submit"
                >
                  CRIAR
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default CreateManager;
